import {defineStore} from 'pinia';
import {Project} from '../models';
interface State {
  projects: Project[];
}

export const useProjectStore = defineStore('project', {
  state: (): State => ({
    projects: [],
  }),

  getters: {
    active(): Project | undefined {
      const project = this.router.currentRoute.value.query.project;
      if (typeof project !== 'string') {
        return undefined;
      }

      return this.find(project);
    },

    find() {
      return (project: string): Project | undefined =>
        this.projects.find((p: Project) => p.id === project);
    },
  },

  actions: {
    update(project: Project) {
      const existingProject = this.projects.find(p => p.id === project.id);

      existingProject
        ? Object.assign(existingProject, project)
        : this.projects.push(project);
    },
  },
});
